<template>
	<div id="app">
		<router-view />
		<CursorCircle v-if="!isMobileUser" />
	</div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import CursorCircle from '@/components/CursorCircle.vue'

@Component({
	components: {
		CursorCircle,
	},
})
export default class App extends Vue {
	isMobileUser = false
	// eslint-disable-next-line
	mounted() {
		if (!navigator.userAgentData) this.isMobileUser = false
		this.isMobileUser = navigator.userAgentData.mobile
	}
}
</script>

<style>
#app {
	font-family: Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	position: relative;
}

#nav a.router-link-exact-active {
	color: #428db9;
}

body,
html {
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

body {
	background-color: #090909;
}

@font-face {
	font-family: 'Helvetica';
	src: url('assets/Helvetica.ttf');
}
</style>
